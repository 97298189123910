import * as React from 'react'
import { Button } from '@shared/components/button'
import { Paths } from '@shared/menu-items/paths'
import { getFullLinkByPath } from '@shared/pipes/dom'
import cn from 'classnames'
import { useRouter } from 'next/router'

import styles from './Submit.module.scss'

type Props = {
	disabled?: boolean
}

export const Submit = ({ disabled }: Props) => {
	const router = useRouter()

	return (
		<div className={cn({ [styles.controls]: router.pathname !== '/wow' })}>
			{router.pathname !== '/wow' && (
				<div className={styles.privacy}>
					Нажимая кнопку «Далее», вы даете согласие
					<br />
					<a
						className='main-link'
						target='_blank'
						rel='noopener noreferrer nofollow'
						href={getFullLinkByPath(Paths.agreement)}
					>
						на обработку персональных данных
					</a>
				</div>
			)}
			<Button
				type='submit'
				disabled={disabled}
				className={cn(styles.submitButton, {
					[styles.fullWidth]: router.pathname === '/wow',
				})}
			>
				Далее
			</Button>
		</div>
	)
}
